module.exports = [{
      plugin: require('../gatsby-myth-source-plugin/gatsby-browser.js'),
      options: {"plugins":[],"mythDomain":"myth.one-pixel-ahead.com","domain":"munichvocalcoaching.de","hash":"c458b4865ce259c7661e156d8840be6a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
